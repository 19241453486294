<template>
    <div id="course-detail">
        <component-education-navbar logoColor="white"></component-education-navbar>
        <div class="content">
            <div class="video">
                <v-breadcrumbs :items="breadcrumbs" divider=">" class="breadcrumbs"></v-breadcrumbs>
                <div class="topic">
                    <span v-if="playingIdx !== null">{{ coursewareList[playingIdx].name }}</span>
                    <div class="courseware-download" @click="showCoursewareListDialog = true" v-if="playingIdx !== null">
                        <i class="icon-courseware"></i>
                        课件下载
                    </div>
                </div>
                <div class="video-box">
                    <div class="lf">
                        <video width="100%" height="100%" controls ref="video">
                            <source src="" type="video/mp4">
                        </video>
                    </div>
                    <div class="rf">
                        <h2>课程列表</h2>
                        <ul>
                            <li v-for="(item, index) in coursewareList" :key="index">
                                <div :class="['courseware-item', index === playingIdx ? 'playing' : '']" @click="chooseCourseware(index)">
                                    {{ item.name }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="comment-content">
                <div class="comment-box">
                    <div class="comment-enter-box" v-if="token">
                        <h2>我要评论</h2>
                        <el-input
                            style="resize: none;"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入"
                            v-model="commentText">
                        </el-input>
                        <div class="btn">
                            <el-button size="small" type="primary" @click="saveComment">发布评论</el-button>
                        </div>
                    </div>
                    <div class="login-tips" v-else>
                        <i class="icon-avatar"></i>
                        <p><router-link to="/login">登录</router-link> 后可以发评论</p>
                    </div>
                    <div class="comments">
                        <div class="total">全部评论 <span>({{ commentTotal }})</span></div>
                        <ul v-if="commentList.length > 0">
                            <li v-for="(item, index) in commentList" :key="index">
                                <div class="student">
                                    <img :src="item.headurl ? eduStaticPath + item.headurl : defaultCommentAvatar" alt="">
                                    <div>
                                        <strong>{{ item.username }}</strong>
                                        <p>{{ item.createTime | formatDate }}</p>
                                    </div>
                                </div>
                                <div class="comment">{{ item.content }}</div>
                            </li>
                        </ul>
                        <div class="comment-empty" v-else>
                            <p>暂无评论!~</p>
                        </div>
                        <div class="check-more" v-if="commentList.length > 0">
                            <button type="button" @click="checkMoreComment">查看更多评论</button>
                        </div>
                    </div>
                </div>
                <div class="teacher-info">
                    <div class="top">
                        <h2>讲师介绍</h2>
                        <div class="teacher-msg">
                            <img :src="eduStaticPath + teacherInfo.headurl" alt="">
                            <div>
                                <h3>{{ teacherInfo.userContact }}</h3>
                                <p>{{ teacherInfo.title }}</p>
                            </div>
                        </div>
                        <div class="intr">
                            {{ teacherInfo.resume }}
                        </div>
                    </div>
                    <div class="other-course">
                        <h2>
                            <span>其他课程</span>
                            <router-link to="/online/list/OTQ%3D/courses">更多</router-link>
                        </h2>
                        <ul>
                            <li v-for="(item, index) in otherCourses" :key="index" v-show="index < 4">
                                <div class="" @click="toOtherCourse(item.id)">
                                    <img :src="eduCoverPath + item.cover" alt="">
                                    <h3>{{ item.name }}</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <el-dialog
                :visible.sync="showCoursewareListDialog"
                :show-close="false"
                :close-on-click-modal="false"
                width="440px"
                height="440px"
            >
                <div class="icon"></div>
                <div class="text" v-if="playingIdx !== null">
                    <h2>{{ coursewareList[playingIdx].textName }}</h2>
                    <p>{{ coursewareList[playingIdx].description }}</p>
                    <p>{{ coursewareList[playingIdx].updateTime | formatDate }}</p>
                    <div class="btn">
                        <span @click="handleDownload">下载</span>
                    </div>
                </div>
            </el-dialog>

            <component-education-bottom showScratchBg showBgColor></component-education-bottom>
        </div>
    </div>
</template>
<script>
    import ComponentEducationNavbar from '@/components/ComponentEducationNavbar'
    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    import {urls, staticPath, eduStaticPath, eduCoverPath, banbaoUrl} from '@/lib/common'
    import defaultCommentAvatar from '@/assets/images/education/avatar.png';
    import man from "../../plugins/man";
    export default {
        name: "CourseDetail",
        components: {
            ComponentEducationNavbar,
            ComponentEducationBottom
        },
        data() {
            return {
                staticPath,
                eduStaticPath,
                eduCoverPath,
                banbaoUrl,
                defaultCommentAvatar,
                breadcrumbs: [
                    {
                        text: '在线教育',
                        disabled: false,
                        href: '/online',
                    }
                ],
                courseId: '',
                pageSize: 10,
                pageCurrent: 1,
                total: 0,
                commentPageCurrent: 1,
                commentTotal: 0,
                teacherInfo: '',
                coursewareList: [],
                otherCourses: [],
                commentList: [],
                courseDetail: '',
                token: '',
                commentText: '',
                hasMoreComment: true,
                showCoursewareListDialog: false,
                playingIdx: null,
                coursewareId: '',
                videoStream: '',

            };
        },
        methods: {
            getOtherCourseList() {
                const params = {
                    pageSize: this.pageSize,
                    pageCurrent: this.pageCurrent,
                    teacherId: this.courseDetail.teacherId
                }
                this.$http.get(urls.getCourseList, {params}).then(res => {
                    if (res.data.status === 200) {
                        const result = res.data.data.records;
                        this.otherCourses = result.filter(item => {
                            return item.id !== this.courseId
                        })
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getTeacherInfo() {
                this.$http.get(urls.getTeacherInfo + this.courseId).then(res => {
                    if (res.data.status === 200) {
                        this.teacherInfo = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getCourseInfo() {
                return new Promise((resolve, reject) => {
                    this.$http.get(urls.getCourseInfo + this.courseId).then(res => {
                        if (res.data.status === 200) {
                            this.courseDetail = res.data.data;
                            let obj = {
                                text: this.courseDetail.name,
                                disabled: true,
                                href: ''
                            }
                            this.breadcrumbs.push(obj);
                        } else if (res.data.status !== 401 && res.data.status !== 106) {
                            this.$message.error(res.data.message)
                        }
                        resolve()
                    }).catch(err => {
                        reject(err)
                    })
                })
            },
            toOtherCourse(id) {
                this.courseId = id;
                this.breadcrumbs.pop();
                this.getCourseInfo().then(() => {
                    this.getCoursewareList();
                    this.getTeacherInfo();
                    this.getOtherCourseList();
                    this.getCommentList();
                    this.$router.replace({
                        name: 'CourseDetail',
                        params: {
                            courseId: encodeURIComponent(btoa(id))
                        }
                    })
                })
            },
            getCommentList() {
                const params = {
                    pageCurrent: this.commentPageCurrent,
                    pageSize: this.pageSize,
                    courseId: this.courseId
                }
                this.$http.get(urls.getCourseComment, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.commentList = res.data.data.records;
                        this.commentTotal = res.data.data.total;
                        if (res.data.data.total > this.commentPageCurrent * this.pageSize) {
                            this.hasMoreComment = true;
                        } else {
                            this.hasMoreComment = false;
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getCoursewareList() {
                const params = {
                    courseId: this.courseId,
                }
                this.$http.get(urls.getCoursewareList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.coursewareList = res.data.data.records;
                        this.total = res.data.data.total;
                        if (this.$route.query && this.$route.query.n) {
                            this.coursewareId = Number(atob(decodeURIComponent(this.$route.query.n)));
                            this.coursewareList.map((item, index) => {
                                if (item.id === this.coursewareId) {
                                    this.playingIdx = index;
                                    return;
                                }
                            })
                            this.coursePreview()
                        }
                        if (this.coursewareList.length > 0) {
                            this.playingIdx = 0;
                            this.coursewareId = this.coursewareList[0].id;
                            this.coursePreview()
                        } else {
                            let date = new Date();
                            let t = date.getDate() + '' + date.getMinutes() + '' + date.getSeconds();
                            this.playingIdx = null;
                            this.$refs.video.src = ' ?t=' + t
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            checkMoreComment() {
                if (this.hasMoreComment) {
                    this.commentPageCurrent++;
                    this.getCommentList();
                } else {
                    this.$message.warning('没有更多评论了!~')
                }
            },
            saveComment() {
                if (!this.commentText) {
                    this.$message.warning('请先输入评论内容...');
                    return
                }
                const params = {
                    content: this.commentText,
                    courseId: this.courseId
                }
                this.$http.post(urls.saveComment, params).then(res => {
                    if (res.data.status === 200) {
                        this.$message.success('评论成功!~');
                        this.commentText = '';
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            handleDownload() {
                const self = this;
                const coursewareId = self.coursewareList[self.playingIdx].id;
                const url = urls.courseDownload + coursewareId;
                self.$http({
                    url,
                    method: 'get',
                    responseType: 'blob'
                }).then(res => {
                    const textName = self.coursewareList[self.playingIdx].textName
                    const fileName = textName.substring(0, textName.lastIndexOf('.'));
                    const extName = textName.substring(textName.lastIndexOf('.') + 1);
                    man.file.blobAsFile(res, fileName, extName, false);
                })
            },
            coursePreview() {
                const self = this;
                const url = urls.previewVideo + self.coursewareId
                self.$http({
                    url,
                    method: 'get',
                    responseType: 'blob'
                }).then((res) => {
                    let data = res.data;
                    let fileReader = new FileReader();
                    fileReader.onload = function() {
                        try {
                            let jsonData = JSON.parse(this.result);
                            if (jsonData.status) {
                                // 说明是普通对象数据，后台转换失败
                                self.$message.warning(jsonData.message)
                                switch(jsonData.status) {
                                    case 106:
                                        sessionStorage.setItem('prevPath', JSON.stringify({
                                            pathName: self.$route.name,
                                            params: self.$route.params
                                        }))
                                        setTimeout(() => {
                                            self.$router.push({
                                                path: '/login'
                                            })
                                        }, 2000)
                                        break;
                                    case 500:
                                        setTimeout(() => {
                                            self.$router.back();
                                        }, 2000)
                                        break;
                                    default:
                                        break;
                                }
                            }
                        } catch (err) {
                            // 解析成对象失败，说明是正常的文件流
                            const blob = res.data;
                            self.$refs.video.onload = function() {
                                window.URL.revokeObjectURL(self.$refs.video.src);
                            };
                            self.$refs.video.src = window.URL.createObjectURL(blob);
                        }
                    };
                    fileReader.readAsText(data);
                });
            },
            chooseCourseware(index) {
                this.playingIdx = index;
                this.coursewareId = this.coursewareList[index].id
                this.coursePreview()
            }
        },
        created() {
            if (this.$cookies.get('app.token', {domain: banbaoUrl, path: ''})) {
                this.token = this.$cookies.get('app.token', {domain: banbaoUrl, path: ''});
            }
            if (this.$route.params && this.$route.params.courseId) {
                this.courseId = Number(atob(decodeURIComponent(this.$route.params.courseId)));
                this.getCourseInfo().then(() => {
                    this.getTeacherInfo();
                    this.getOtherCourseList();
                    this.getCommentList();
                    this.getCoursewareList();
                })
            }
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #course-detail {
        min-width: 1380px;
        min-height: 100vw;

        .content {
            height: 100%;
            box-sizing: border-box;
            @{deep} .breadcrumbs {
                width: 70vw;
                padding: 24px 0 12px;
                .v-breadcrumbs__divider,
                .v-breadcrumbs__item {
                    color: #fff;
                }
            }
            .video {
                margin: auto;
                display: flex;
                flex-wrap: wrap;
                padding: 5vw 15vw;
                color: #fff;
                background: #F2FCFF url("../../assets/images/education/course_detail_bg.png") no-repeat center center;
                background-size: 100% 100%;
                .topic {
                    /*display: flex;*/
                    /*justify-content: space-between;*/
                    /*align-items: flex-end;*/
                    width: 100%;
                    span {
                        font-size: 32px;
                        float: left;
                    }
                    .courseware-download {
                        float: right;
                        padding: 0 2vw;
                        border-radius: 10vw;
                        background: #fff;
                        color: #43425D;
                        cursor: pointer;
                        font-weight: bold;
                        height: 2.2vw;
                        line-height: 2.2vw;
                        .icon-courseware {
                            display: inline-block;
                            width: 1vw;
                            height: 1vw;
                            background: url("../../assets/images/education/icon_download.png") no-repeat center center;
                            background-size: 100%;
                            position: relative;
                            top: 0.2vw;
                        }
                    }
                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }
                }
                .video-box {
                    display: flex;
                    width: 100%;
                    align-items: flex-end;
                    background: #3A3369;
                    margin-top: 1vw;
                    .lf {
                        width: 75%;
                        height: 100%;
                        box-sizing: border-box;
                        >div {
                            height: 100%;
                        }
                    }
                    .rf {
                        width: 25%;
                        box-sizing: border-box;
                        padding: 1vw;
                        h2 {
                            font-size: 18px;
                            margin: 0.5vw 0;
                        }
                        ul {
                            height: 28vw;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                                width: 3px;
                                height: 12px;
                                background-color: transparent;
                            }
                            &::-webkit-scrollbar-track {
                                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                                border-radius: 10px;
                                background-color: transparent;
                            }
                            &::-webkit-scrollbar-thumb {
                                border-radius: 10px;
                                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                                background-color: #767297;
                            }
                            li {
                                margin-top: 1vw;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-break: break-all;
                                white-space: nowrap;
                                .courseware-item {
                                    color: rgba(255, 255, 255, 0.7);
                                    font-size: 16px;
                                    line-height: 20px;
                                    cursor: pointer;
                                    &.playing {
                                        color: #1BB2FF;
                                        position: relative;
                                    }
                                    &.playing::before {
                                        content: '';
                                        display: inline-block;
                                        border: 6px solid transparent;
                                        border-left-color: #1BB2FF;
                                        border-radius: 3px;
                                        position: relative;
                                        top: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .comment-content {
                padding: 2vw 15vw 6vw;
                display: flex;
                align-items: flex-start;
                background: #F3FCFF;
                .comment-box {
                    width: 60%;
                    margin-right: 3%;
                    color: #333333;
                    padding-top: 4vw;
                    background: url("../../assets/images/education/comment_bg.png") top center;
                    background-size: 100%;
                    .comment-enter-box {
                        background: #fff;
                        padding: 0 20px;
                        h2 {
                            font-size: 18px;
                            line-height: 200%;
                        }
                        @{deep} .el-textarea {
                            textarea {
                                resize: none;
                            }
                        }
                        .btn {
                            text-align: right;
                            margin-top: 20px;
                        }
                    }
                    .login-tips {
                        padding: 0 1vw;
                        background: #fff;
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                        .icon-avatar {
                            float: left;
                            width: 3vw;
                            height: 3vw;
                            background: url("../../assets/images/education/avatar.png") no-repeat center center;
                            background-size: 100%;
                            box-sizing: border-box;
                            position: relative;
                            top: 1vw;
                            margin-right: 10px;
                        }
                        >p {
                            width: calc(100% - 3vw - 10px);
                            margin-top: 1vw;
                            border: 1px solid #ddd;
                            float: left;
                            line-height: 3vw;
                            padding: 0 10px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            >a {
                                color: #1BB2FF;
                                cursor: pointer;
                            }
                        }
                    }
                    .comments {
                        padding: 0 1vw 3vw;
                        background: #fff;
                        .total {
                            padding-top: 2vw;
                            font-size: 18px;
                            color: #333;
                            font-weight: bold;
                            span {
                                color: #999;
                                font-weight: normal;
                                font-size: 1vw;
                            }
                        }
                        ul {
                            li {
                                padding: 1vw 0 1.5vw;
                                border-bottom: 1px dashed #ccc;
                                .student {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    >img {
                                        width: 60px;
                                        height: 60px;
                                        margin-right: 14px;
                                    }
                                    >div {
                                        line-height: 150%;
                                        strong {
                                            font-size: 15px;
                                        }
                                        p {
                                            color: #999;
                                        }
                                    }
                                }
                                .comment {
                                    padding-left: 74px;
                                    margin-top: 10px;
                                    font-size: 18px;
                                }
                            }
                        }
                        .check-more {
                            text-align: center;
                            margin: 3vw 0 0;
                            button {
                                color: #0047BB;
                                padding: 1vw 2vw;
                                background: #C6F2FF;
                                border-radius: 4px;
                                font-size: 18px;
                            }
                        }
                    }
                    .comment-empty {
                        text-align: center;
                        font-size: 16px;
                        p {
                            margin: 40px 0;
                        }
                    }
                }
                .teacher-info {
                    width: 38%;
                    padding: 5vw 0 1vw;
                    display: flex;
                    flex-wrap: wrap;
                    background: url("../../assets/images/education/teacher_bg.png") no-repeat top center;
                    background-size: 100%;
                    .top {
                        width: 100%;
                        font-size: 18px;
                        background-color: #fff;
                        padding: 0 1vw 3vw;
                        h2 {
                            font-size: 1.875vw;
                        }
                        .teacher-msg {
                            margin: 1.5vw 0 1vw;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-end;
                            img {
                                width: 120px;
                                height: 120px;
                            }
                            >div {
                                margin-left: 1vw;
                                h3 {
                                    font-size: 18px;
                                    color: #333;
                                    margin-bottom: .6vw;
                                }
                                p {
                                    color: #a5a5a5;
                                }
                            }
                        }
                        .intr {
                            color: #3B3B3B;
                            line-height: 180%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                        }
                    }
                    .other-course {
                        width: 100%;
                        border-top: 1px dashed #C6F2FF;
                        padding: 0 1vw 2vw;
                        >h2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            font-size: 18px;
                            padding: 1vw 0;
                            a {
                                display: inline-block;
                                padding: 4px 10px;
                                border-radius: 2vw;
                                background: #00A66C;
                                color: #fff;
                                font-size: 0.83vw;
                                font-weight: normal;
                                &::after {
                                    content: '';
                                    display: inline-block;
                                    width: 6px;
                                    height: 6px;
                                    border: 1px solid #fff;
                                    border-right: 0;
                                    border-top: 0;
                                    transform: rotate(-135deg);
                                    position: relative;
                                    top: -2px;
                                }
                            }
                        }
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            li {
                                width: 48%;
                                text-align: center;
                                margin-right: 4%;
                                margin-bottom: 20px;
                                cursor: pointer;
                                &:nth-child(even) {
                                    margin-right: 0;
                                }
                                img {
                                    border-radius: 10px;
                                }
                                h3 {
                                    margin-top: 10px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
            @{deep} .el-dialog {
                border-radius: 10px;
                text-align: center;
                color: #34254C;
                font-size: 18px;
                .icon {
                    margin: 0 auto 30px;
                    width: 72px;
                    height: 86px;
                    background: url("../../assets/images/education/icon_courseware_download.png") no-repeat center center;
                    background-size: 100%;
                }
                h2 {
                    font-size: 24px;
                }
                p {
                    margin: 10px 0;
                }
                .btn {
                    span {
                        color: #fff;
                        cursor: pointer;
                        display: inline-block;
                        width: 200px;
                        height: 50px;
                        line-height: 50px;
                        border-radius: 50px;
                        background: linear-gradient(to right bottom, #FF4B7C, #DC000C);
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #course-detail{
            min-width: 320px;
            .content{
                .video{
                    padding:20% 2.6% 10%;
                    .topic{
                        padding-bottom: 2%;
                        span{
                            .fontFn(20);
                        }
                        .courseware-download{
                            padding: 0 4%;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            .fontFn(12);
                            .fontFn(24,height);
                            .fontFn(24,line-height);
                            .icon-courseware{
                                .fontFn(14,width);
                                .fontFn(14,height);
                            }
                        }
                    }
                    .video-box{
                        padding-bottom: 4%;
                        flex-direction: column;
                        .lf{
                            width: 100%;
                            height: auto;
                        }
                        .rf{
                            width: 96%;
                            margin:0 auto;
                            h2{
                                .fontFn(16);
                                margin:0;
                                padding: 10px 0;
                            }
                            ul{
                                height: auto;
                                max-height: 72px;
                                li{
                                    margin-top:0;
                                    margin-bottom: 4px;
                                }
                            }
                        }
                    }
                }
                .breadcrumbs{
                    width: 100%;
                    padding: 8% 0 4%;
                    @{deep} li{
                        .fontFn(14);

                    }
                }
                .comment-content{
                    padding: 0% 2.6%;
                    flex-direction: column;

                    .comment-box{
                        width: 100%;
                        margin-right: 0;
                        padding-top: 8%;
                        margin-bottom: 8%;
                        .comment-enter-box{
                            padding: 0 5.2%;
                            h2{
                                .fontFn(18);
                                .fontFn(40,line-height);
                                .fontFn(40,height);
                            }
                            @{deep} .el-textarea{
                                textarea{
                                    .fontFn(14);
                                    .fontFn(20,line-height);
                                }
                            }
                            .btn{
                                margin-top:4%;
                            }
                        }
                        .comments{
                            padding: 4% 5.2% 10%;
                            .total{
                                padding-top: 0;
                                padding-bottom: 4%;
                                .fontFn(18);
                                span{
                                    .fontFn(12);
                                }
                            }
                            ul{
                                li{
                                    padding: 2% 0 4%;
                                    .student{
                                        &>img{
                                            .fontFn(60,width);
                                            .fontFn(60,height);
                                            .fontFn(20,margin-right);
                                        }
                                        &>div{
                                            strong{
                                                .fontFn(14);
                                                .fontFn(20,line-height);
                                            }
                                            p{
                                                .fontFn(12);
                                                .fontFn(16,line-height);
                                            }
                                        }
                                    }
                                    .comment{
                                        .fontFn(16);
                                        .fontFn(80,margin-left);
                                        padding-left: 0;

                                    }
                                }
                            }
                            .check-more{
                                margin:3% 0 0;
                                button{
                                    .fontFn(14);
                                }
                            }
                        }
                    }
                    .teacher-info{
                        width: 100%;
                        padding: 10% 0 4%;
                        .top{
                            padding: 0;
                            margin-bottom: 5%;
                            h2{
                                .fontFn(18);
                                padding: 2% 5.2%;
                            }
                            .teacher-msg{
                                margin: 0;
                                padding: 0 5.2% 4%;
                                &>div{
                                    margin-left: 2%;
                                    margin-bottom: 2%;
                                    h3{
                                        .fontFn(18);
                                    }
                                    p{
                                        .fontFn(14);
                                    }
                                }
                            }
                            .intr{
                                .fontFn(16);
                                padding: 0 5.2% 6%;
                            }
                        }
                        .other-course{
                            padding: 3% 0;
                            &>h2{
                                padding: 0 0 4%;
                                .fontFn(18);
                                a{
                                    .fontFn(12);
                                }
                            }
                            ul{
                                li{
                                    width: 100%;
                                    margin-right: 0;
                                    margin-bottom: 6%;
                                    h3{
                                        .fontFn(16);
                                    }
                                }
                                
                            }
                        }
                    }
                }

                @{deep} .el-dialog{
                    width: 94% !important;
                }
            }
        }
    }
</style>